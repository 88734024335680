<template>
    <DxToolbar style="height: 56px" class="toolbar">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <routerVue :breadcrumbsItems="breadcrumbsItems" />
            </template>
        </DxItem>
    </DxToolbar>
    <div class="row">
        <div class="xs12" style="padding: 6px 0px 6px 24px">
            <DxButton
                type="normal"
                icon="mdi mdi-refresh"
                styling-mode="contained"
                hint="Tải lại"
                @click="reload()"
            />
            <DxButton
                type="normal"
                icon="mdi mdi-plus-circle"
                styling-mode="contained"
                class="ml-2"
                @click="ThemTaiKhoan()"
                hint="Thêm mới"
                v-if="
                    $Helper.Common.CheckRole(
                        $t('Quyen.CauHinh'),
                        $t('Quyen.ThemSuaTaiKhoanHoaDonDienTu')
                    )
                "
            />
            <DxButton
                type="normal"
                icon="mdi mdi-pencil"
                styling-mode="contained"
                class="ml-2"
                @click="SuaTaiKhoan(this.arrSelected[0])"
                :disabled="arrSelected.length != 1"
                hint="Sửa"
                v-if="
                    $Helper.Common.CheckRole(
                        $t('Quyen.CauHinh'),
                        $t('Quyen.ThemSuaTaiKhoanHoaDonDienTu')
                    )
                "
            />
            <DxButton
                type="normal"
                icon="mdi mdi-delete"
                styling-mode="contained"
                class="ml-2"
                :disabled="arrSelected.length != 1"
                @click="XoaTaiKhoan(arrSelected[0])"
                hint="Xóa"
                v-if="
                    $Helper.Common.CheckRole(
                        $t('Quyen.CauHinh'),
                        $t('Quyen.XoaTaiKhoanHoaDonDienTu')
                    )
                "
            />
            <!-- :disabled="arrSelected.length == 0" -->
            <DxButton
                type="normal"
                icon="mdi mdi-file-excel"
                styling-mode="contained"
                class="ml-2"
                @click="exportExcel"
                hint="Xuất Excel"
            />
        </div>
    </div>

    <div class="row pl-4">
        <div class="xs12">
            <DxDataGrid
                id="gridContainer"
                class="table-page"
                :remote-operations="false"
                :show-borders="true"
                :data-source="DanhSachTaiKhoanHoaDonDienTu"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-row-lines="true"
                ref="DanhSachTaiKhoanHoaDonDienTu"
                :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
                no-data-text="Không có dữ liệu!"
                height="calc(100vh - 56px - 48px)"
                @cellHoverChanged="onCellHoverChanged"
                @selection-changed="onSelectionChanged"
            >
                <DxHeaderFilter :visible="true" :allow-search="true" />
                <DxPaging :page-size="50" />
                <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                <DxFilterRow :visible="true" />
                <DxSelection
                    select-all-mode="allPages"
                    show-check-boxes-mode="always"
                    mode="multiple"
                />
                <DxColumn
                    data-field="tenTaiKhoanHeThong"
                    caption="Tên tài khoản hệ thống QLBX"
                />
                <DxColumn
                    data-field="tenTaiKhoanHDDT"
                    caption="Tài khoản hóa đơn điện tử"
                />
                <DxColumn
                    data-field="matKhauHDDT"
                    caption="Mật khẩu hóa đơn điện tử"
                    cellTemplate="tmpMatKhau"
                />
                <template #tmpMatKhau="{ data }">
                    {{ data.data.matKhauHDDT.replace(/./g, "*") }}
                </template>

                <DxSummary>
                    <DxTotalItem
                        column="tenTaiKhoanHeThong"
                        summary-type="count"
                        display-format="Tổng: {0}"
                        :show-in-group-footer="true"
                        :align-by-column="true"
                    />
                </DxSummary>
                <!-- Hover button -->
                <DxColumn
                    cssClass="column-button-hover"
                    data-field="Detail"
                    caption="Trạng thái"
                    width="1"
                    cell-template="buttons-cell"
                    :fixed="true"
                    fixed-position="right"
                ></DxColumn>
                <template #buttons-cell="{ data }">
                    <div class="buttons">
                        <DxButton
                            id=""
                            icon="mdi mdi-pencil"
                            @click="SuaTaiKhoan(data.data)"
                            hint="Sửa"
                            v-if="
                                $Helper.Common.CheckRole(
                                    $t('Quyen.CauHinh'),
                                    $t('Quyen.ThemSuaTaiKhoanHoaDonDienTu')
                                )
                            "
                        ></DxButton>
                        <DxButton
                            id=""
                            icon="mdi mdi-delete"
                            class="mx-2"
                            @click="XoaTaiKhoan(data.data)"
                            hint="Xóa"
                            v-if="
                                $Helper.Common.CheckRole(
                                    $t('Quyen.CauHinh'),
                                    $t('Quyen.XoaTaiKhoanHoaDonDienTu')
                                )
                            "
                        ></DxButton>
                    </div>
                </template>
            </DxDataGrid>
        </div>
    </div>
    <DxPopup
        v-if="ifPopupXacNhanXoa"
        v-model:visible="PopupXacNhanXoa"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-close-button="false"
        :show-title="false"
        :width="388"
        height="auto"
        @hidden="onHidden('PopupXacNhanXoa')"
    >
        <PopupXacNhanVue
            :Params="{
                WidthTitle: '90%',
                Title: 'Bạn có chắc muốn xóa cấu hình đang chọn này?',
                btnLeft: 'HỦY',
                btnRight: 'XÁC NHẬN',
            }"
            @click="XacNhanXoa"
        />
    </DxPopup>

    <DxPopup
        v-if="ifPopupThongTinTaiKhoanHoaDonDienTu"
        v-model:visible="PopupThongTinTaiKhoanHoaDonDienTu"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="false"
        :width="488"
        height="auto"
        @hidden="onHidden('PopupThongTinTaiKhoanHoaDonDienTu')"
    >
        <PopupThongTinTaiKhoanHoaDonDienTuVue
            :item="item"
            @hiddenPopup="hiddenPopup"
            @LayDanhSachTaiKhoanHoaDonDienTu="LayDanhSachTaiKhoanHoaDonDienTu"
        />
    </DxPopup>
</template>

<script>
import { mapState } from "vuex";

import {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxSummary,
    DxFilterRow,
    DxTotalItem,
    DxScrolling,
    DxHeaderFilter,
    DxPaging,
} from "devextreme-vue/data-grid";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import routerVue from "@sonphat/common-v1/components/router.vue";
import { DxButton, DxPopup } from "devextreme-vue";
import PopupXacNhanVue from "@sonphat/common-v1/components/PopupXacNhan.vue";
import PopupThongTinTaiKhoanHoaDonDienTuVue from "./components/PopupThongTinTaiKhoanHoaDonDienTu.vue";
export default {
    components: {
        DxToolbar,
        DxItem,
        routerVue,
        DxButton,
        DxDataGrid,
        DxColumn,
        DxSelection,
        DxFilterRow,
        DxSummary,
        DxTotalItem,
        DxPopup,
        PopupXacNhanVue,
        DxScrolling,
        DxHeaderFilter,
        DxPaging,
        PopupThongTinTaiKhoanHoaDonDienTuVue,
    },

    data() {
        return {
            LyDoXoa: "",
            ifPopupXacNhanXoa: false,
            PopupXacNhanXoa: false,
            arrSelected: [],
            paramXacNhanXoa: {
                WidthTitle: "90%",
                Title: "Xác nhận xóa tài khoản",
                btnLeft: "HỦY",
                btnRight: "ĐỒNG Ý",
            },
            breadcrumbsItems: [
                {
                    id: "breadcrums_DieuDo",
                    text: "Tài khoản hóa đơn điện tử",
                    disabled: true,
                    to: "",
                },
                // {
                //     id: "breadcrums_DieuDo",
                //     text: this.$t("stringRouter.QLBD"),
                //     disabled: true,
                //     to: "",
                // },
            ],
            item: {},
            ifPopupThongTinTaiKhoanHoaDonDienTu: false,
            PopupThongTinTaiKhoanHoaDonDienTu: false,
        };
    },
    created() {
        this.LayDanhSachTaiKhoanHoaDonDienTu();
    },

    computed: {
        ...mapState({
            DanhSachTaiKhoanHoaDonDienTu: (state) =>
                state.CongHoaDonDienTu.DanhSachTaiKhoanHoaDonDienTu,
        }),
    },

    methods: {
        onHidden(name) {
            this["if" + name] = false;
        },
        hiddenPopup(name) {
            this[name] = false;
        },
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        async reload() {
            await this.$refs.DanhSachTaiKhoanHoaDonDienTu.instance.clearSorting();
            await this.$refs.DanhSachTaiKhoanHoaDonDienTu.instance.clearSelection();
            await this.$refs.DanhSachTaiKhoanHoaDonDienTu.instance.clearFilter();
            setTimeout(async () => {
                this.LayDanhSachTaiKhoanHoaDonDienTu();
                await this.$refs.DanhSachTaiKhoanHoaDonDienTu.instance.clearFilter();
            }, 300);
        },
        async LayDanhSachTaiKhoanHoaDonDienTu() {
            let rs = await this.$store.dispatch(
                "CongHoaDonDienTu/LayDanhSachTaiKhoanHoaDonDienTu",
                {
                    guidBenXe: this.$Core.Auth.TokenParsed.ID_BenXeSuDung,
                }
            );
        },
        exportExcel() {
            let init = {
                Element: this.$refs.DanhSachTaiKhoanHoaDonDienTu.instance,
                FileName: "Danh-Sach-Tai-Khoan-Hoa-Don",
                WorkSheet: "Danh Sách",
            };
            this.$Helper.Common.excel(init).Export();
        },

        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
        onSelectionChanged(e) {
            this.arrSelected = e.selectedRowsData;
        },
        ThemTaiKhoan() {
            this.item = {};
            this.showPopup("PopupThongTinTaiKhoanHoaDonDienTu");
        },
        SuaTaiKhoan(data) {
            this.item = JSON.parse(JSON.stringify(data));
            this.showPopup("PopupThongTinTaiKhoanHoaDonDienTu");
        },
        XoaTaiKhoan(data) {
            this.item = JSON.parse(JSON.stringify(data));
            this.showPopup("PopupXacNhanXoa");
        },
        validate() {
            return this.$refs.formValidation.instance.validate();
        },
        async XacNhanXoa(bool) {
            if (bool == true) {
                this.PopupXacNhanXoa = false;
                try {
                    // XoaCauHinhTaiKhoanKetNoiHddt;
                    let rs = await this.$Events.RequestPost({
                        keyRequest: "XoaCauHinhTaiKhoanKetNoiHddt",
                        ServicesBE: "CongHoaDonDienTu",
                        url: this.$t("urlCHDDT.XoaCauHinhTaiKhoanKetNoiHddt"),
                        dataPost: {
                            idCauHinh: this.item.idCauHinh,
                        },
                        msgSuccess: "Xóa thông tin tài khoản hóa đơn điện tử thành công!",
                        msgError: "Xóa thông tin tài khoản hóa đơn điện tử thất bại!",
                        msgCatch: "Xóa thông tin tài khoản hóa đơn điện tử thất bại!",
                        typeNotificationSuccess: "Toast",
                        typeNotificationError: "Popup",
                    });
                } catch (error) {
                    this.$stopLoading;
                } finally {
                    this.LayDanhSachTaiKhoanHoaDonDienTu();
                }
            } else if (bool == false) {
                // Huy
                this.PopupXacNhanXoa = false;
            }
            this.item = {};
        },
    },
};
</script>

<style scope></style>
